import React from "react";
import { Container } from "@ui/wrapper";
import Motto from "@components/motto";
import { SectionWrap, StyledHeading } from "./style";

const CtaArea = () => {
  return (
    <SectionWrap>
      <Container>
        <StyledHeading>¿Listo para contratarnos?</StyledHeading>
        <Motto text="Solicitar" linkText="presupuesto" path="/contacto" />
      </Container>
    </SectionWrap>
  );
};

export default CtaArea;
