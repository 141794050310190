import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "@ui/wrapper";
import Timeline from "@ui/timeline";
import SectionTitle from "@ui/section-title";
import { SectionWrap } from "./style";

const QuienesSomosArea = () => {
  const data = useStaticQuery(graphql`
    {
      image1: file(relativePath: { eq: "nosotros/timeline-image-01.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      image2: file(relativePath: { eq: "nosotros/timeline-image-02.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      image3: file(relativePath: { eq: "nosotros/timeline-image-03.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const { image1, image2, image3 } = data;
  const items = [
    {
      id: 1,
      title: "Eventos Ghupa",
      description:
        "Somos una empresa que presta servicios en la ciudad de Lima. Participamos personalmente en todos los aspectos de nuestros servicios. Los líderes de la industria y las personas influyentes confían en nosotros para crear el tipo de eventos sobresalientes que buscan; llevamos ese mismo ímpetu a las bodas de las que luego no pueden dejar de hablar sobre la fiesta. Hemos refinado habilidades a lo largo de los años para hacer los mejores eventos. Somos un poco nerds, y nos divertimos mucho. Estamos absolutamente obsesionados con los detalles e igualmente hipnotizados por las historias de cada pareja y cliente con el que trabajamos.",
      images: [
        {
          src: image1,
        },
      ],
    },
    {
      id: 2,
      title: "Nosotros",
      description:
        "Nos complace atender todas sus necesidades de equipos de alquiler para eventos y fiestas, de manera profesional y asequible. Ya sea que se trate de alquiler de toldos para fiestas, alquiler de sonido y luces para fiestas, alquiler de barras, alquiler de pantallas, hora loca, podemos hacer realidad su gran evento. Todo el mundo quiere que su fiesta transcurra sin problemas, pero a menudo las pequeñas cosas se dejan demasiado tarde y se olvidan. Podemos encargarnos de todo, desde las bodas más elegantes hasta eventos corporativos a precios sorprendentemente buenos. ¡Permítanos hacer que su fiesta, boda o evento especial sea aún más mágico!",
      images: [
        {
          src: image2,
        },
      ],
    },
    {
      id: 2,
      title: "Nuestro servicio",
      description:
        "Más que una empresa audiovisual, somos socios en su evento. De principio a fin, ya sea pequeño o grande, estamos aquí para garantizar un evento exitoso.",
      images: [
        {
          src: image3,
        },
      ],
    },
  ];
  return (
    <SectionWrap>
      <Container>
        <SectionTitle mb={["45px"]} title="Quienes Somos" />
        <Timeline items={items} />
      </Container>
    </SectionWrap>
  );
};

export default QuienesSomosArea;
