import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Heading from "@ui/heading";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import { PageHeaderWrap } from "./style";

const PageHeader = ({ pageContext, location, title }) => {
  const imageData = useStaticQuery(graphql`
    query breadCrumbImgQuery {
      file(relativePath: { eq: "bg/title-bar-01-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
            formats: WEBP
            quality: 100
          )
        }
      }
    }
  `);
  const bgImage = convertToBgImage(getImage(imageData.file));
  return (
    <PageHeaderWrap {...bgImage}>
      <Heading as="h1">{title}</Heading>
    </PageHeaderWrap>
  );
};

PageHeader.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    breadcrumb: PropTypes.shape({
      crumbs: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  title: PropTypes.string,
};

export default PageHeader;
